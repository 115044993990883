@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Apercu';
  src: local('Apercu'), url('/public/fonts/ApercuRegular.woff2') format('woff2');
  /* font-weight: normal; */
  /* font-style: normal; */
  font-display: swap;
}

body {
  @apply text-secondary;
  font-family: Apercu;
  overflow-x: hidden;
}


/* ::-webkit-scrollbar {
  width: 12px;
} */
/* ::-webkit-scrollbar-thumb {
  background-color: var(--dark-slate);
  border: 3px solid var(--navy);
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: var(--navy);
} */
::selection {
  background-color: #112240;
  @apply text-secondary-lightest;
}
