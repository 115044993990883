.slider-container {
  width: 100%;
  overflow: hidden;
  min-height: 100px;
}

.slider-list {
  display: flex;
  width: 200%;
  animation: slide 28s linear infinite;
}

.slider-list.reverse {
  display: flex;
  width: 200%;
  animation: slide 28s linear infinite reverse;
}

.slider-list li {
  flex: 1;
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
