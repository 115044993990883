.Particles {
}
canvas {
  max-width: 100%;
}

#particle-slider {
  width: 100%;
  height: 100%;
  min-height: 557px;
  margin: 0;
  /* margin-left:-224px; */
  /* margin-top:-202px; */
  padding: 0;
  overflow: hidden;
  background-position: center top;
}
